export const LinkData = [
  {
    id: 1,
    title: "Home",
    url: "/",
  },
  {
    id: 2,
    title: "About",
    url: "/about",
  },
  {
    id: 3,
    title: "courses",
    url: "/courses",
  },
  {
    id: 4,
    title: "Team",
    url: "/team",
  },
  {
    id: 5,
    title: "blogs",
    url: "/blog",
  },
]
export const courses = [
  {
    id: 1,
    cover: "../images/two.jpg",
    title: "MERN Stack Development (Onsite)",
    description: "Master the full-stack web development process using MongoDB, Express, React, and Node.js in an interactive onsite environment.",
  },
  {
    id: 2,
    cover: "../images/one.jpg",
    title: "MERN Stack Development (Online)",
    description: "Learn full-stack web development remotely with live classes, expert guidance, and real-world projects.",
  },
  {
    id: 3,
    cover: "../images/three.png",
    title: "Basic Web Development (Online)",
    description: "Build a strong foundation in web development with HTML, CSS, and JavaScript, tailored for beginners.",
  },
  {
    id: 4,
    cover: "../images/four.jpeg",
    title: "Basic Web Development (Onsite)",
    description: "Learn web development essentials in a hands-on, classroom environment with dedicated mentors.",
  },
  {
    id: 5,
    cover: "../images/five.png",
    title: "Backend Development (Onsite)",
    description: "Dive deep into server-side programming, API development, and database management in an onsite format.",
  },
  {
    id: 6,
    cover: "../images/six.jpg",
    title: "Backend Development (Online)",
    description: "Master backend technologies like Node.js, Express, and databases through our online program.",
  },
  {
    id: 7,
    cover: "../images/three.png",
    title: "Social Media Marketing",
    description: "Learn the art of creating impactful campaigns, growing audiences, and managing platforms like Facebook, Instagram, and LinkedIn.",
  },
  {
    id: 8,
    cover: "../images/six.jpg",
    title: "Graphics Designing (Basic)",
    description: "Kickstart your design career with fundamental skills in tools like Photoshop and Illustrator.",
  },
  {
    id: 9,
    cover: "../images/two.jpg",
    title: "Graphics Designing (Advanced)",
    description: "Take your design skills to the next level with advanced techniques for branding, UI/UX, and digital media.",
  },
];
